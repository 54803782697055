import React from 'react';
import './App.css';
import Playlist from './components/Playlist';

function App() {
  return (
    <Playlist />
  );
}

export default App;
